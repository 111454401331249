import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag"
import { createGtm } from "vue-gtm";
import Ads from "vue-google-adsense"

import './index.css'

createApp(App).use(VueGtag, {
    config: { id: "G-JF7J58JYL9" }
}).use(
    createGtm({
        id: "GTM-NB37F2B"
    })
).use(Ads.Adsense).mount('#app')
