<template>
<div class="p-4">
<h1 class="font-bold">
Your number one source for all of your lobster timing needs
</h1>
</div>

<div>
<p class="p-4">
Enter total weight of lobsters:
<input @input="onInput" placeholder="Total weight" class="border-solid border-2">
</p>
</div>

<div class="p-4">
<ol class="list-decimal list-inside">
  <li>Add one inch of water to lobster pot</li>
  <li>Rinse the lobsters off in the sink with cold water</li>
  <li>Add lobsters to cold water, and turn on the stove</li>
  <li>Once the water is boiling, cook for {{ cookingTime }} minutes</li>
  <li>Melt 2 tablespoons of butter per lobster</li>
  <li>Take the lobsters out of the pot, and rinse with cold water again</li>
  <li>Plate and serve</li>
</ol>
</div>

<div class="google-ads">
    <Adsense
            data-ad-client="ca-pub-1858648483391576">
    </Adsense>
</div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
        cookingTime: 0
    }
  },
  methods: {
               onInput(e) {
                var weight = parseInt(e.target.value);
                this.cookingTime = 5 + ((weight-1)*3)
               }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
